import { useState, useEffect } from "react";
import { Select } from "@mantine/core";
import _ from "lodash";
// import JsonViewer from "./common/jsonViewer";
// import ReactJson from "react-json-view";
import { useServerApi } from "../hooks/useServerApi";
import { useForceUpdate } from "@mantine/hooks";

const DataSelect = ({
  form,
  name,
  sourceField,
  label,
  value,
  onChange,
  placeholder,
  required,
  clearable,
  sourceEntity,
  sourceId,
  sourceApiEntity,
  sourceApiField,
  ...props
}) => {
  const [api] = useServerApi();
  const sourceDataId = _.get(form.values, sourceId);
  const [options, setOptions] = useState([]);
  const forceUpdate = useForceUpdate();

  /////
  // const source = _.get(form.values, sourceField);
  // const options =
  //   source?.map((item) => ({
  //     value: item._id,
  //     label: item.name,
  //   })) ?? [];
  /////

  const fetchData = async () => {
    try {
      const data = await api.getById({
        apiEntity: sourceApiEntity,
        id: sourceDataId,
      });
      console.log("DataSelect fetchData result=> ", data);
      const source = _.get(data, sourceApiField);
      const o =
        source?.map((item) => ({
          value: item._id,
          label: item.name,
        })) ?? [];
      setOptions(o);
      console.log("o=> ", o);
      forceUpdate();
      // form.setFieldValue(sourceField, data);
    } catch (e) {
      console.log(e);
    }
  };
  const handleClick = (e) => {
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [value]);

  return (
    <>
      {/* sourceDataId: {sourceDataId} */}
      {/* sourceField: {sourceField} / value: {value} <br />
      options: <ReactJson src={options} collapsed /> */}
      <Select
        data={options}
        value={value}
        onChange={onChange}
        label={label}
        placeholder={placeholder}
        required={required}
        clearable={clearable}
        // searchable={true}
        onClick={handleClick}
        {...props}
      />
    </>
  );
};

export default DataSelect;
